@import "../../../Stylesheets/Base/color-palette";

.footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 1053;
  width: 100%;
  background-color: $white;
  border-top: 2px solid $red;

  &__icon-container {
    padding: 0.3em 0.3em;
  }
  &__icon {
    padding: 0.3em 0.3em;
    cursor: pointer;
    background-color: #7b7b7c;
    border-radius: 20px;
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
