.send-message-form {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	grid-template-areas:
		"main main main"
		"left center right";
	&__textinput {
		grid-area: main;

		max-width: 100%;

		textarea {
			min-height: 8rem;
			max-height: 10rem;
			resize: vertical;
			// font-family: "Open Sans", sans-serif;
			font-size: 1.1rem;
			padding: 0.8rem;
		}
	}
	&__submit {
		grid-area: right;
		flex-grow: 0;
	}
	&__button-container {
		width: 100%;
		display: flex;
		gap: 7px;
	}

	&__call-to-action {
		display: flex;
		justify-content: space-between;
		vertical-align: middle;
	}

	&__message-information {
		font-size: 10px;
		display: flex;
		gap: 7px;
		&__title {
			font-weight: bold;
		}
	}
}

.message-informations {
	grid-area: center;
	justify-self: end;
}
.emoticon-container {
	grid-area: left;
}

.conversation-start-date {
	font-size: 10px;
}

// .send-message-form {
// 	margin-bottom: 3rem;
// 	display: grid;
// 	gap: 0.5em;
// 	grid-template-areas:
// 		"main main main"
// 		"left center right";
// 	&__textinput {
// 		grid-area: main;

// 		max-width: 100%;

// 		textarea {
// 			min-height: 8rem;
// 			max-height: 10rem;
// 			resize: vertical;
// 			// font-family: "Open Sans", sans-serif;
// 			font-size: 1.1rem;
// 			padding: 0.8rem;
// 		}
// 	}
// 	&__submit {
// 		grid-area: right;
// 		flex-grow: 0;
// 	}
// 	&__button-container {
// 		width: 100%;
// 		display: flex;
// 	}
// }

// .message-informations {
// 	grid-area: center;
// 	justify-self: end;
// }
// .emoticon-container {
// 	grid-area: left;
// }

// .conversation-start-date {
// 	font-size: 10px;
// }
