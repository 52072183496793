@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";

.top-navigation {
	&__nav-list-container {
		// padding-top: 10px;
		// padding-bottom: 10px;
	}
	&__navigation {
		// background-color: $blue;
		width: 100%;

		&--active {
			background-color: $white;
		}

		&__list {
			display: flex;
			background-color: $blue;
			padding: 5px 10px;
			align-items: center;
			justify-content: space-between;
			gap: 5px;
			@media screen and (min-width: $desktopMaxWidth) {
				gap: 20px;
			}

			&__item {
				color: $white;
				&--sticky-left {
					justify-self: flex-start;
				}
				&--sticky-right {
					justify-self: flex-end;
				}

				&--alternate {
					color: $blue;
				}

				// &--activated {
				// 	color: $blue;
				// }
			}
			&--alternate {
				background-color: $white;
				.top-navigation__navigation__link {
					color: $blue !important;
				}
				.top-navigation__navigation__link--active {
					color: $white !important;
					background-color: $blue;
				}
			}
			&--left {
				display: flex;
				width: 50%;
				justify-content: flex-end;
			}
		}

		&__link {
			padding: 5px 10px;
			border-radius: 40px;
			display: flex;
			color: $white !important;
			align-items: center;
			gap: 10px;

			&.mobile {
				@media screen and (min-width: $customMedium) {
					gap: 10px;
				}
			}

			&--active {
				background-color: $white;
				color: $blue !important;

				border-radius: 40px;
				display: grid;
				grid-template-columns: 20px auto;
				gap: 10px;

				@media screen and (max-width: $desktopMaxWidth) {
					align-items: center;
				}
			}
			&--alternate {
				color: $blue !important;
			}
		}
	}
}

.burger-navigation-menu {
	height: 0;
	// -webkit-transition: height 0.3s ease-in-out;
	// -moz-transition: height 0.3s ease-in-out;
	// -o-transition: height 0.3s ease-in-out;
	// transition: height 0.3s ease-in-out;
	overflow: hidden;
	background-color: $blue;

	&__navigation {
		@media screen and (min-width: 360px) and (max-width: $customMedium) {
			width: 100%;
		}
		&__list {
			display: flex;
			gap: 3px;
			padding-right: 20px;
			align-items: center;

			@media screen and (max-width: $desktopMaxWidth) {
				gap: 5px;
			}

			&__item {
				display: flex;
				width: 100%;

				&:any(.burger-navigation-menu__navigation__link--active) {
					background-color: $white;
				}
			}
		}

		&__link {
			color: $white !important;
			padding: 10px 20px;
			display: flex;
			justify-content: center;
			gap: 50px;
			width: 100%;

			@media screen and (max-width: $desktopMaxWidth) {
				padding: 5px 10px;
			}
			// &:hover {
			// 	background-color: $white;
			// 	color: $blue;
			// }
			&--active {
				background-color: $white;
				color: $blue !important;
				padding: 10px 20px;

				@media screen and (max-width: $desktopMaxWidth) {
					padding: 5px 10px;
					align-items: center;
				}
			}

			& > .icon {
				align-self: flex-start;
			}
		}
	}

	&__drop {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 0;
		height: 100%;
		@media screen and (min-width: $customMedium) {
			display: none;
		}
		// -webkit-transition: height 0.3s ease-in-out;
		// -moz-transition: height 0.3s ease-in-out;
		// -o-transition: height 0.3s ease-in-out;
		// transition: height 0.3s ease-in-out;
	}
}
