@import "../../../Stylesheets/Base/color-palette";
.card-navigation {
  display: flex;
  align-items: center;
  nav {
    ul {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      // gap: 50px;
      justify-items: center;
    }
  }

  &__link {
    padding: 0.8rem 1.6rem;
    &:link {
      border: 2px transparent;
    }
    &:hover,
    &--active {
      border: 2px solid lightpink;
      border-radius: 10px;
    }
  }
}
