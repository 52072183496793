@import "../../Base/color-palette";

.react-calendar {
  &__navigation {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "label label label label"
      "prev prev next next";
    gap: 10px;

    &__label {
      grid-area: label;
      border: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      &:hover {
        // background-color: rgb(128, 206, 225, 0.3);
        background-color: $cream80;
        cursor: pointer;
      }
      &:disabled {
        &:hover {
          background: rgba(239, 239, 239, 0.3);
        }
      }
    }
    &__prev2-button &__prev-button {
      grid-area: prev;
    }

    &__next2-button &__next-button {
      grid-area: prev;
    }
    &__arrow {
      padding: 10px;
      border: none;
      cursor: pointer;
      font-weight: 600;
      &:disabled {
        cursor: not-allowed;
        &:hover {
          background: rgba(239, 239, 239, 0.3);
        }
      }
      &:hover {
        background: $cream80;
        // background-color: rgb(128, 206, 225, 0.3);
      }
      &--active {
        // background-color: rgb(128, 206, 225, 0.3);
        background: $cream80;
      }
    }
  }

  &__month-view {
    margin-top: 10px;
    &__days {
      margin-top: 10px;
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
      padding: 10px 0;
      &__day {
        max-width: 90px !important;
      }
    }
    &__weekdays {
      padding: 10px 0;
      text-align: center;
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      &__weekday {
        justify-self: center;
        min-width: fit-content !important;
      }
    }
  }
  &__year-view {
    &__months {
      margin-top: 10px;
      gap: 7.5px;
      justify-content: center;
    }
  }

  &__decade-view {
    &__years {
      margin-top: 10px;
      gap: 7.5px;
      justify-content: center;
    }
  }

  &__tile {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    border: none;
    max-width: 83px !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    flex-grow: 1;
    &:hover {
      //background-color: rgb(128, 206, 225, 0.3);
      background: $cream80;
    }
    &--active {
      //  background-color: rgb(128, 206, 225, 0.3);
      background: $cream80;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background: rgba(239, 239, 239, 0.3);
      }
    }
  }
}
