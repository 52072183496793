@import "../../../Stylesheets/Base/color-palette";

.duplicate-message-box {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0.48rem;
	background-color: $cream80;
	margin-bottom: 5px;

	&__title {
		font-weight: 700;
	}

	&__item {
		&--highlight {
			background-color: $yellow;
		}
		margin-bottom: 5px;
		&::before {
			font-family: "Material Icons Outlined";
			font-weight: normal;
			font-style: normal;
			content: "error";
			margin-right: 7px;
		}
	}
}
