@import "../../Stylesheets/Base/color-palette";
.flowbooster-presentation-card {
	background-color: $blue;
	border-radius: 10px;
	box-shadow: 0.5px 0.5px 3px 0.5px grey;

	&__inner-layer {
		background-color: $white;
		height: 100%;
		color: $blue;
		border-top-right-radius: 50%;
		border-bottom-left-radius: 50%;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__intro {
		text-align: center;
		span {
			display: block;
		}

		&__brand {
			font-size: 56px;
			font-family: "Open Sans", sans-serif;
		}
	}
}
