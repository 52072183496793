@import "../../Stylesheets/Base/breakpoints";

.overview-item {
	display: flex;
	justify-content: space-between;


	&--column {
		flex-direction: column;
	}

	&__icon {
		cursor: pointer;
	}

	&__info {
		&__title {
		}
	}

	&__details-container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		&--grid {
			display: grid;
		}
	}

	&__details {
		display: flex;
		gap: 10px;
		&--grid {
			display: grid;
			gap: 5px;
			grid-template-columns: minmax(80px, 1fr) 80px 80px;
		}

		&__title {
			font-weight: 700;
		}
		&__description {
		}
	}
}
