@import "../../Stylesheets/Structure/structure";
@import "../../Stylesheets/Base/color-palette";
@import "../../Stylesheets/Base/breakpoints";

.login-panel {
	height: 100%;
	width: 100%;
	background-color: $light-blue;
	border: 1px solid $blue;
	@media screen and (min-width: $customMedium) {
		background-color: $light-grey;
		border: none;
	}

	&__white-overlay {
		background-color: $white;
		border-top-right-radius: 55%;
		height: 100%;
		border-bottom-left-radius: 55%;
	}
	&__logo {
		color: $blue;
		padding: 5px 10px;

		&--text {
			font-size: 35px !important;
		}
	}
	&__intro-text {
		color: $blue;
		font-size: 40px;
		text-align: center;
		margin-top: 25%;
		margin-bottom: 25%;
		font-family: "Open Sans", sans-serif;
	}

	&__addendum {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		margin-top: 40px;
		font-size: 10px;
		@media screen and (min-width: $customMedium) {
			margin-top: 30px;
		}
	}
	&__link {
		color: $blue !important;
	}
	&--grid {
		display: grid;
		grid-template-columns: repeat(2, 50%);
		grid-template-columns: repeat(2, 50%);
		max-height: 500px;
		gap: 7px;
		justify-content: center;

		&__right {
			width: 450px;
			height: 500px;
			justify-self: flex-start;
		}
		&__left {
			width: 450px;
			height: 500px;
			justify-self: flex-end;
		}
	}

	&__top-content {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 20px;
		color: $gray-800;
		padding: 10px 30px 0 30px;
		h1 {
			font-size: 18px;
		}
		p {
			font-size: 10px;
		}
	}
}

.login-container {
	width: 100%;
	height: 100%;
	background-color: $light-grey;
	display: flex;
	align-items: center;
	justify-items: center;
}
