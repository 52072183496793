@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";

.account-tarification-form {
  &__actions-container {
    gap: 20px;
  }
  &__submit-button {
    width: 50px;
    height: 40px;
  }
}

.tarification-details-card {
  @media screen and (max-width: $extraLargeDeviceMinWidth) {
    grid-area: body;
  }
}
