@import "../../Stylesheets/Base/color-palette";
.accordion {
	&__header {
		display: flex;
		justify-content: space-between;
		background-color: $secondary;
		width: 100%;
		padding: 0.5em;
		cursor: pointer;

		&__title {
			text-transform: uppercase;
			font-weight: bold;
			//   align-self: flex-start;
		}
		&__icon {
			//   align-self: flex-end;
		}
		&--active {
			&::after {
				font-family: "Material Icons Outlined";
				font-weight: normal;
				font-size: 1.5em;
				font-style: normal;
				content: "add";
				line-height: 1;
			}
		}

		&--inactive {
			&::after {
				font-family: "Material Icons Outlined";
				font-weight: normal;
				font-style: normal;
				font-size: 1.5em;
				content: "remove";
				line-height: 1;
			}
		}
	}
	&__elements {
		padding: 0 1em;
		display: flex;
		flex-direction: column;
		background-color: $grey30;
		// display: none;
		// opacity: 0
		// z-index: -1;
		transition: max-height 2s ease-out;
	}

	&--active {
		// display: block;
		// opacity: 1;
		// transition: opacity 1s ease-in 2s;
		max-height: fit-content;
	}

	&--inactive {
		overflow: hidden;
		max-height: 0;
	}
}
