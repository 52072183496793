@import '../../../Stylesheets/Base/color-palette';  

.notification-card{
    border-radius: 0px;
    box-shadow: 2.5px 2.5px 5px 2.5px rgba(128, 128, 128, 0.247);
    &__header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray-400;
        align-items: center;
        padding-bottom: 10px;
        &__title{
            font-weight: 600; 
        }
    }
    &__content{
        display: flex; 
        align-items: center;
        padding: 30px;
        
        & p {
            padding-left: 30px; 
        }
    }
    &__list{
        margin-top: 50px; 
        padding: 20px; 
        display: flex; 
        gap: 30px; 
    }
}; 
.notification-alert{
    display: flex;
    align-items: center;
    background-color: $gray-500; 
    padding: 10px; 
    margin: 10px 0; 
    &__link{
        text-align: center;
        width: 100%; 
  
    }
}