@import "../../../Stylesheets/Base/color-palette";

@mixin button {
	padding: 5px 10px;
	border-radius: 4px;
	color: white;
	font-family: "Open Sans", sans-serif;
	display: flex;
	cursor: pointer;
	border: none;
}

@mixin buttonOutlinedBase($color) {
	background-color: transparent;
	border: 1px solid $color;
	color: $color;
}

@mixin buttonOutlinedHoverBase($color) {
	background-color: $color;
	color: white;
}

@mixin buttonRounded($color, $hoverColor) {
	border-radius: 40px;
	background-color: $color;
	color: white;
	&:hover {
		background-color: $hoverColor;
	}
}

@mixin buttonConfiguration($baseColor, $hoverColor) {
	background-color: $baseColor;
	&:hover {
		background-color: $hoverColor;
	}
	&--outlined {
		@include buttonOutlinedBase($baseColor);
		&:hover {
			@include buttonOutlinedHoverBase($hoverColor);
		}
	}
	// &--rounded {
	//   @include buttonRounded($baseColor, $hoverColor);
	// }
}

.button {
	@include button();
	span {
		&:nth-child(2) {
			margin-left: 10px;
			line-height: 2;
		}
	}

	&--rounded {
		border-radius: 40px;
	}
	&--primary {
		@include buttonConfiguration($blue, $blue80);
	}

	&--secondary {
		@include buttonConfiguration($cream, $cream80);
	}

	&--success {
		@include buttonConfiguration($darkGreen, $green);
	}

	&--danger {
		@include buttonConfiguration($red, $red80);
	}

	&--warning {
		@include buttonConfiguration($orange, $orange80);
	}

	&--info {
		@include buttonConfiguration($greyBlue, $greyBlue80);
		color: $blue;
		background-color: transparent;
		border: 2px $blue solid;
	}

	&--disabled {
		cursor: not-allowed !important;
		background-color: $gray-300 !important;
		color: black !important;
	}

	&--small {
		font-size: 0.8rem !important;
		padding: 2px 4px;
	}
	&--medium {
		padding: 5px 10px;
	}
	&--large {
		padding: 7px 14px;
		font-size: 1.4rem !important;
	}
	&--icon {
		.material-icons-outlined {
			font-size: 1em;
		}
	}
}
