@import "../../../Stylesheets/Base/breakpoints";

.daily-stats-view {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
	gap: 0.5rem;

	margin-top: 0.58rem;

	&__label {
		font-weight: bold;
	}
}
