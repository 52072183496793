/*Background color */

$primaryBackground: #6db5ca;

$secondaryBackground: #b8d8e0;

$ternaryBackground: #ff7235;

$quarternaryBackground: #fcf5ef;

/*new palette */

$accentColor1: #e99a27;
$accentColor2: #666eab;
$neutralColor1: #ffffff;
$neutralColor2: #cef6ff;
$additionalColor1: #07668e;
$additionalColor2: #3c6ba0;

/*end of new palette */

$blue: #07668e;
$greyBlue: #b8d8e0;
$cream: #f5be59;
$orange: #e99a27;
$red: #cc0c53;
$grey: #eeeff0;

$light-grey: #f8f9f9;
$light-blue: #e9f8ff;

$green: #64edbc;
$darkGreen: #006400;
$gold: #ffd700;

$blue80: #07668ecc;
$greyBlue80: #b8d8e0cc;
$orange80: #ff7235cc;
$cream80: #f5be59cc;
$red80: #cc0c53cc;
$grey80: #eeeff0cc;
$green80: #64edbccc;

$blue50: #07668e80;
$greyBlue50: #b8d8e080;
$orange50: #ff723580;
$cream50: #f5be5980;
$red50: #cc0c5380;
$grey50: #eeeff080;
$green50: #64edbc80;

$blue30: #07668e4d;
$greyBlue30: #b8d8e04d;
$orange30: #ff72354d;
$cream30: #f5be594d;
$red30: #cc0c534d;
$grey30: #eeeff04d;
$green30: #64edbc4d;

$red5: #cc0c5221;

/*text color*/

$normalText: black;
$importantText: #ed6495;
$errorText: #ed6495;
$success: #64edbc;
$highlightedText: #6495ed;

/* Links */

$lightBlue: #009ef6;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
// $orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$gray: #8e887a;
$gray-dark: #403d38;

$white: #ffffff;
$light: #f8f6f2;
$primary: #4fc9da;
$secondary: #dad3c3;
$success: #b8d935;
$info: #4f55da;
$warning: #e8c444;
$danger: #f06445;
$dark: #211f1c;
$white-rgb: rgb(255, 255, 255);
$light-rgb: rgb(248, 246, 242);
$primary-rgb: rgb(79, 201, 218);
$secondary-rgb: rgb(218, 211, 195);
$success-rgb: rgb(184, 217, 53);
$info-rgb: rgb(79, 85, 218);
$warning-rgb: rgb(232, 196, 68);
$danger-rgb: rgb(240, 100, 69);
$dark-rgb: rgb(33, 31, 28);
$white-rgb: rgb(255, 255, 255);
$black-rgb: rgb(0, 0, 0);
$body-color-rgb: rgb(33, 31, 28);
$body-bg-rgb: rgb(255, 255, 255);

$light-primary: #ddf8fc;
$active-primary: #30a6b6;
$light-success: #f4fbdb;
$active-success: #9fbe22;
$light-info: #eff0ff;
$active-info: #383ebc;
$light-warning: #fdf4d6;
$active-warning: #cfab2a;
$light-danger: #fff0ed;
$active-danger: #db5437;
$light-dark: #f6f1e9;
$active-dark: #191715;
$gray-100: #f8f6f2;
$gray-200: #f6f1e9;
$gray-300: #dad3c3;
$gray-400: #b5b0a1;
$gray-500: #968e7e;
$gray-600: #8e887a;
$gray-700: #716d66;
$gray-800: #403d38;
$dark-gray: #4a595d; 
$gray-900: #211f1c;
$grey-blue: #4a595d; 
