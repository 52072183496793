@import "../../Stylesheets/Base/breakpoints";

.add-stats {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and (max-width: $desktopMaxWidth) {
    max-width: 100%;
  }

  .current-week--graph {
    max-width: 65%;

    @media screen and (max-width: $desktopMaxWidth) {
      max-width: 40%;
    }
  }
}

.monthly-stats {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.weekly-stats {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.stats {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;

  &-datepicker {
    &--small {
      @media screen and (max-width: $extraLargeDeviceMinWidth) {
      }
      @media screen and (min-width: $extraLargeDeviceMinWidth) {
        display: none;
      }
    }

    &--large {
      @media screen and (max-width: $extraLargeDeviceMinWidth) {
        display: none;
      }
      @media screen and (min-width: $extraLargeDeviceMinWidth) {
      }
    }
  }

  &__graph {
    &--inner {
      @media screen and (max-width: $veryLargeDeviceMaxWidth) {
        display: none;
      }
    }

    &--outter {
      width: 100%;

      @media screen and (max-width: $veryLargeDeviceMaxWidth) {
        display: block;
      }
      @media screen and (min-width: $extraLargeDeviceMinWidth) {
        display: none;
      }
    }
  }
}

.messages-stats {
  &-grid {
    @media screen and (max-width: $extraLargeDeviceMinWidth) {
      //grid-template-columns: 1fr;
      display: flex;
      flex-wrap: wrap;
    }
    &--col-4 {
      @media screen and (max-width: $extraLargeDeviceMinWidth) {
        //grid-template-columns: 1fr;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }
  }

  &__stats-card {
    @media screen and (max-width: $extraLargeDeviceMinWidth) {
      max-height: 400px;
    }
  }
}

// here is the responsive for the statistic messages-states
div.row--grid:nth-child(2) {
  @media screen and (min-width: 768px) and (max-width: 868px) {
    display: flex;
    flex-direction: column;
  }

}
div.row--grid:nth-child(2) .card {
  @media screen and (min-width: 868px) {
    min-width: 350px;
  }
}
