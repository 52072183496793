@import "../../../Stylesheets/Base/color-palette";

.breadcrumb {
	display: flex;
	align-items: center;
	padding: 10px 0 0 10px;
	color: $gray;

	&__link {
		font-size: 10.8px;
		color: $gray !important;
	}
	& .icon--outlined {
		font-size: 14px;
	}
	&--list {
		display: flex;
		margin-bottom: 50px;
	}
}
