.messages-feedback-container {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.original-message-container {
	width: 80%;
	margin-bottom: 1rem;
	&__original-message {
		display: flex;
		flex-direction: column;
		margin-bottom: 0.6rem;
		gap: 0.6rem;
		&__title {
			display: inline-block;
		}
	}
	&__button-box {
		display: flex;
		gap: 1rem;
	}
}

.message-feedback-box {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 5px;
	&__text-box {
		width: 75%;
		display: flex;
		flex-direction: column;
		textarea {
			width: 100%;
			height: 300px;
			resize: none;
			font-size: 1.2rem;
		}
	}

	&__reason-box {
		width: 20%;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;

		& > * {
			height: fit-content;
		}
	}
}
