@import "./color-palette";
@import "./breakpoints";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-size: 0.7rem;

	@media screen and (min-width: $customMedium) {
		font-size: 0.75rem;
	}

	@media screen and (min-width: $large) {
		font-size: 0.85rem;
	}
	a {
		text-decoration: none;
		cursor: pointer;

		&:link {
			color: $normalText;
		}
		&:hover {
			color: $normalText;
		}
		&:active {
			color: $normalText;
		}
		&:visited {
			color: $normalText;
		}
	}

	li {
		list-style-type: none;
	}

	button {
		cursor: pointer;
	}
}

.not-selectable {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}
