.overview-notification {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-bottom: 30px;
  &:last-child {
    .vertical-line {
      border-left: 2px solid lightgray;
      height: 1rem;
    }
  }
  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__vertical-line {
    border-left: 2px solid lightgray;
    height: 3rem;
  }

  &__description {
    display: flex;
    flex-direction: column;

    &--title {
      color: black;
      font-weight: 700;
    }
    &--date {
      color: gray;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
