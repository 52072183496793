@import "../../Stylesheets/Base/color-palette";
@import "../../Stylesheets/Base/breakpoints";

@mixin tableRow($columnAmount) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  padding-top: 10px;
  padding-bottom: 10px;
  color: $grey-blue;
  // border-bottom: 1px solid lightgray;
  position: relative;
}

@mixin defaultRow($columnAmount) {
  @include tableRow($columnAmount);
  padding-top: 20px;
  padding-bottom: 20px;
}

@mixin defaultHeader($columnAmount) {
  @include tableRow($columnAmount);
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  border-bottom: 1px solid $gray;
  text-align: center;
}

.overview-table {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  &::after {
    content: "";
    padding-bottom: 40px;
  }
  & > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__header,
  &__row {
    word-break: break-all;
    &.overview-table__row--col-6 > div,
    &.overview-table__row--col-4__before-last-two-column__large--last__small
      > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .element-actions.not-show{
    visibility: hidden; 
  }
  &__actions {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.326);
    margin-left: 150%; 
    overflow: hidden;
    animation-name: slideout;
    animation-duration: 0.5s;
  
   
    
    &.show{
      animation-name: slidein; 
      animation-duration: 3s;
    }

    & a:link,
    & a:visited {
      padding-right: 20px;
      color: $grey-blue;
   
    }
    & .icon--outlined {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
       
       &:hover{
         background-color: $gray-300;
       }
    }
    
  }
   & .expand-right {
     display: none;
     &.show{
       display: flex;
     }
     
   }
   & .expand-left {
    display: flex;
    &.not-show{
      display: none;
    }
    
  }
  &__row:nth-child(odd) {
    background-color: $light-blue;
  }

  //header
  &__header--col-2 {
    @include defaultHeader(2);
  }
  &__header--col-3 {
    @include defaultHeader(3);
  }
  &__header--col-4 {
    @include defaultHeader(4);
  }
  &__header--col-5 {
    @include defaultHeader(5);
  }
  &__header--col-6 {
    @include defaultHeader(6);
  }
  &__header--col-12 {
    @include defaultHeader(12);
  }

  &__header--col-2__last-column__large {
    @include defaultHeader(4);
    grid-template-columns: 150px 1fr;
  }

  &__header--col-3__last-column__large {
    @include defaultHeader(5);
    grid-template-columns: 150px 150px 1fr;
  }

  &__header--col-4__last-column__large {
    @include defaultHeader(4);
    grid-template-columns: 150px 150px 150px 1fr;
  }

  &__header--col-5__last-column__large {
    @include defaultHeader(5);
    grid-template-columns: 150px 150px 150px 150px 1fr;
  }

  &__header--col-6__last-column__large {
    @include defaultHeader(6);
    grid-template-columns: 150px 150px 150px 150px 150px 1fr;
  }

  &__header--col-3__last-two-column__large {
    @include defaultHeader(4);
    grid-template-columns: 150px 1fr 1fr;
  }

  &__header--col-4__last-two-column__large {
    @include defaultHeader(4);
    grid-template-columns: 150px 150px 1fr 1fr;
  }

  &__header--col-5__last-two-column__large {
    @include defaultHeader(5);
    grid-template-columns: 150px 150px 150px 1fr 1fr;
  }

  &__header--col-6__last-two-column__large {
    @include defaultHeader(6);
    grid-template-columns: 150px 150px 150px 150px 1fr 1fr;
  }

  &__header--col-3__before-last-two-column__large--last__small {
    @include defaultHeader(4);
    grid-template-columns: 1fr 1fr 150px;
  }

  &__header--col-4__before-last-two-column__large--last__small {
    @include defaultHeader(4);
    grid-template-columns: 150px 1fr 1fr 150px;
  }

  &__header--col-5__before-last-two-column__large--last__small {
    @include defaultHeader(5);
    grid-template-columns: 150px 150px 1fr 1fr 150px;
  }

  &__header--col-6__before-last-two-column__large--last__small {
    @include defaultHeader(6);
    grid-template-columns: 150px 150px 150px 1fr 1fr 150px;
  }

  //end of header

  &__row--col-2 {
    @include defaultRow(2);
  }
  &__row--col-3 {
    @include defaultRow(3);
  }
  &__row--col-4 {
    @include defaultRow(4);
  }
  &__row--col-5 {
    @include defaultRow(5);
  }
  &__row--col-6 {
    @include defaultRow(6);
  }
  &__row--col-12 {
    @include defaultRow(12);
  }

  /* last column large*/
  &__row--col-2__last-column__large {
    @include defaultRow(4);
    grid-template-columns: 150px 1fr;
  }

  &__row--col-3__last-column__large {
    @include defaultRow(4);
    grid-template-columns: 150px 150px 1fr;
  }
  &__row--col-4__last-column__large {
    @include defaultRow(4);
    grid-template-columns: 150px 150px 150px 1fr;
  }

  &__row--col-5__last-column__large {
    @include defaultRow(5);
    grid-template-columns: 150px 150px 150px 150px 1fr;
  }

  &__row--col-6__last-column__large {
    @include defaultRow(6);
    grid-template-columns: 150px 150px 150px 150px 150px 1fr;
  }

  /* end of last column large*/
  /* last two column large */

  &__row--col-3__last-two-column__large {
    @include defaultRow(5);
    grid-template-columns: 150px 1fr 1fr;
  }
  &__row--col-4__last-two-column__large {
    @include defaultRow(4);
    grid-template-columns: 150px 150px 1fr 1fr;
  }

  &__row--col-5__last-two-column__large {
    @include defaultRow(5);
    grid-template-columns: 150px 150px 150px 1fr 1fr;
  }

  &__row--col-6__last-two-column__large {
    @include defaultRow(6);
    grid-template-columns: 150px 150px 150px 150px 1fr 1fr;
  }
  /* last two column large */

  &__row--col-3__before-last-two-column__large--last__small {
    @include defaultRow(4);
    grid-template-columns: 1fr 1fr 150px;
  }

  &__row--col-4__before-last-two-column__large--last__small {
    @include defaultRow(4);
    grid-template-columns: 150px 1fr 1fr 150px;
  }

  &__row--col-5__before-last-two-column__large--last__small {
    @include defaultRow(5);
    grid-template-columns: 150px 150px 1fr 1fr 150px;
  }

  &__row--col-6__before-last-two-column__large--last__small {
    @include defaultRow(6);
    grid-template-columns: 150px 150px 150px 1fr 1fr 150px;
  }
}

.card.overview-item__column {
  @media screen and (max-width: $customMedium) {
    box-shadow: 0px 0px 0px 0px transparent;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
@keyframes slideout {
  from {
    margin-left: 0%;
    width: 100%;
  
  }

  to {
    margin-left: 100%;
    width: 300%;
  }
}

/* table row */

/* end of table row*/

/* table header */

/*end of table header */
