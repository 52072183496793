@import "../../../Stylesheets/Base/color-palette";

.statistics {
	&--emphasis {
		font-weight: 700;
	}
	&--negative {
		color: $red;
		&::before {
			content: "trending_down";
			font-family: "Material Icons";
		}
		// &::after {
		// 	content: "%";
		// }
	}

	&--positive {
		color: $green;
		&::before {
			content: "trending_up";
			font-family: "Material Icons";
		}
		// &::after {
		//   content: "%";
		// }
	}

	&--neutral {
		color: $cream;
		&::before {
			content: "trending_flat";
			font-family: "Material Icons";
		}
		// &::after {
		// 	content: "%";
		// }
	}
}
