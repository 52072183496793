@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";
.side-navigation {
	padding-left: 20px;
	padding-right: 20px;
	height: 100vh;
	position: fixed;
	top: 44px;
	left: -2000px;
	background: $blue;
	padding: 0;
	transition: left 0.3s ease-in-out;
	z-index: 10000;
	overflow-y: scroll;

	&--active {
		transition: left 0.3s ease-in-out;
		left: 0;
	}

	&__link {
		padding: 5px 10px;
		list-style-type: none;
		display: flex;
		width: fit-content;
		color: $white !important;
		@media only screen and (min-width: $laptopMaxWidth) {
			display: grid;
			grid-template-columns: 50px 1fr;
			padding: 10px 20px;
		}

		&:hover {
			color: $primary;
		}
		&:active {
			color: $primary;
		}
		&--active {
			color: $primary;
		}

		&__text {
			display: none;
			@media only screen and (min-width: $laptopMaxWidth) {
				display: block;
			}
		}
	}

	&__list-item {
		margin-bottom: 5px;

		@media only screen and (min-width: $laptopMaxWidth) {
			margin-bottom: 0;
			border-bottom: 0;
		}
		&:hover {
			border-radius: 10px;
			color: $primary;
		}
		&:active {
			color: $primary;
		}
	}

	&__navigation-section {
		&__title {
			display: none;
			padding-left: 15px;
			padding-top: 20px;
			padding-bottom: 20px;

			@media only screen and (min-width: $laptopMaxWidth) {
				display: block;
			}
		}
	}

	&__backdrop__black {
		background-color: rgb(0, 0, 0, 0.5);
		min-width: 100vh;
		min-height: 100vh;
		max-width: 100%;
		max-height: 100%;
		z-index: 1530;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		&--active {
			display: block;
		}
	}

	&__inner-container {
		position: sticky;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}
	&:-webkit-scrollbar-track {
		background: $white;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $white;
		border-radius: 20px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: $white;
	}
}
