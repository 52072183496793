.edit-operator-form{
  
   &__icon-container{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    
    
    }
    &__icon-container .icon{
        font-size: 40px; 
        cursor: pointer; 
    }
    &__container{
        display: flex;
    }
   
}

.edit-operator-form .form{
    display: none;
}

.edit-operator-form .form.active {
    display: flex; 
    width: 100%; 
    border: 1px solid black; 
    border-radius: 12px; 
}






.__tooltip{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px; 
    position: relative;
}
.__tooltip-text{
    font-size: 10px;
    border: 1px solid black; 
    padding: 5px; 
    border-radius: 8px; 
    background-color: black; 
    color: white;
    visibility: hidden;
}
.__tooltip-text::before{
    content: "";
    display: block;
    width: 10px; 
    height: 10px; 
    background-color: black;
    position: absolute; 
    left: 45%; 
    top: 58%; 
    transform: rotate(42deg);
}
.__tooltip:hover .__tooltip-text{
 visibility: visible;
}

