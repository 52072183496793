@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";

.messaging-container {
	//grid-template-columns: 450px 1fr 450px;

	@media only screen and (min-width: $customMedium) {
		display: grid;
		grid-template-columns: 25% 1fr 25%;
		gap: 5px;
		grid-template-areas:
			"left  main  right"
			"bottom	bottom	bottom";
	}

	&__right,
	&__left {
		padding: 1rem 0;
		max-height: fit-content;
	}

	&__left {
		grid-area: left;
		background-color: $light-grey;
	}

	&__right {
		grid-area: right;
		background-color: $light-grey;
		@media only screen and (min-width: $customMedium) {
			background-color: $light-blue;
		}
	}

	&__main {
		margin-top: 10px;
		grid-area: main;
		padding: 0 1rem;
		@media only screen and (min-width: $mobileMinWidth) and (max-width: $medium) {
			padding: 0;
			//order: 0;
		}
	}

	&__bottom {
		grid-area: bottom;
	}

	&__information {
		.accordion__header {
			background-color: $blue;
			color: $white;
		}
		.accordion__elements {
			background-color: $white;
		}
	}
}

.flowbooster-session {
	margin: 0 auto;
	@media screen and (min-width: $customMedium) {
		max-width: 50%;
	}
	&__intro {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&__text {
		}
	}
}
