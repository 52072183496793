@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";

.card {
	border-radius: 10px;
	padding: 1rem 1rem;
	box-shadow: 0.5px 0.5px 3px 0.5px grey;
	width: 100%;

	h6 {
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		color: grey;
	}
	&--white {
		background-color: $white;
	}
	&--grey {
		background-color: $light-grey;
	}
	&--blue {
		background-color: $blue;
		color: $white;
	}
	&--cream {
		background-color: $cream30;
	}
	&--light-blue {
		background-color: $light-blue;
	}
	&--orange {
		background-color: $orange;
	}
	&--image {
		width: 100%;
		color: white;
		position: relative;
		overflow: hidden;
		z-index: 1;

		a {
			color: white;
			&:link {
				color: white;
			}
			&:visited {
				color: white;
			}
		}

		img {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			z-index: -1;
			object-fit: cover;
			background-size: cover;
			top: 0;
			left: 0;
		}
	}
}

.card--information {
	width: 50%;
	h6 {
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		color: grey;
	}
	p {
		margin-top: 20px;
	}
}

.card--information__full-grid {
	flex-direction: column;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	h6 {
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		color: grey;
	}

	p {
		margin-top: 20px;
	}
}

.card--information__full {
	flex-direction: column;

	h6 {
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		color: grey;
	}

	p {
		margin-top: 20px;
	}
}

.card-item {
	display: flex;
	// justify-content: space-around;
	height: max-content;
	flex-direction: column;

	.card--information {
		width: 50%;

		p {
			margin-top: 20px;
		}
	}
	.card {
		width: 50%;
	}

	.card--flex {
		display: flex;
	}

	.card--flex__focus {
		justify-content: center;
		align-items: center;

		span {
			// text-transform: uppercase;
			// font-weight: 700;
			letter-spacing: 0.1em;
		}
	}

	.card--call-to-action {
		margin-top: 65px;
		// display: flex;
		// align-items: flex-end;
		.link {
			display: flex;
			align-items: flex-end;
			cursor: pointer;
		}
	}

	.card--call-to-action__end {
		align-self: flex-end;
	}

	.card--call-to-action__start {
		align-self: flex-start;
	}
}

.card-header--left {
	justify-self: flex-start;
}

.card-header--right {
	justify-self: flex-end;
}

.card--flex {
	display: flex;
}

.card--flex__focus {
	justify-content: center;
	align-items: center;
}

.card__big {
	min-width: 1116px;
	height: auto;
}

.card__medium {
	min-width: 750px;
	height: auto;
}

.card__small {
	min-width: 515px;
	height: auto;
}
.card.overview-item__column {
	@media screen and (max-width: $customMedium) {
		border: none;
	}
}
