@import "../../Stylesheets/Base/breakpoints";
.registration-panel {
	display: flex;
	flex-wrap: wrap;
	margin: 1rem 2rem;
	justify-content: center;
	@media screen and (min-width: $veryLargeDeviceMinWidth) {
		gap: 3px;
	}

	&--right {
		display: none;
		@media screen and (min-width: $veryLargeDeviceMinWidth) {
			display: flex;
			max-width: 50%;
		}
	}

	&--left {
		min-width: 50%;
		@media screen and (min-width: $veryLargeDeviceMinWidth) {
			width: 45%;
			min-width: 0;
		}
	}
}
