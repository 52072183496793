@import "../../../Stylesheets/Base/breakpoints";
@import "../../../Stylesheets/Base/color-palette";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &--grid {
    display: grid;
  }

  &__side {
    max-height: 100vh;
    width: 50px;

    position: sticky;
    left: 0;
    top: 0;

    z-index: 2;
    @media only screen and (min-width: $laptopMaxWidth) {
      width: 20%;
    }

    @media only screen and (min-width: $desktopMaxWidth) {
      max-width: 14%;
    }
  }

  &__main-container {
    display: flex;
    //width: calc(100vw - 50px);
    width: 100%;
    flex-wrap: wrap;

    // @media only screen and (min-width: $laptopMaxWidth) {
    // 	width: calc(100vw - 24%);
    // }

    // @media only screen and (min-width: $desktopMaxWidth) {
    // 	width: calc(100vw - 20%);
    // }

    &__main {
      min-height: 100vh;
      width: 100%;

      overflow-y: auto;
    }
    &__header {
      position: sticky;
      top: 0;
      z-index: 1533;
      width: 100%;
      height: max-content;
    }
    &__footer {
      position: sticky;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: max-content;
    }
  }
}
.dashboard-title {
  color: $gray-800;
  font-weight: 600;
  font-size: 18px;
}
