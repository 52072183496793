@import "../../Stylesheets/Base/color-palette";

.modal-container {
	// position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}
.backdrop__black {
	background-color: rgb(0, 0, 0, 0.5);
	min-width: 100vh;
	min-height: 100vh;
	max-width: 100%;
	max-height: 100%;
	z-index: 1530;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.modal {
	display: flex;
	position: fixed;

	max-width: 50%;
	flex-wrap: wrap;
	top: 10%;

	padding: 20px 20px;
	z-index: 3000;

	&__header {
		font-weight: 700;
		display: inline-flex;
		background: $cream;
		width: 100%;
		justify-content: space-between;
		padding: 10px 10px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		&__close {
			cursor: pointer;
			text-transform: uppercase;
		}
		&__title {
			text-transform: capitalize;
		}
	}

	&__content {
		width: 100%;
		padding: 20px 20px;
	}

	&__card {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		width: 100%;
	}

	&__action {
		width: 100%;
		padding: 20px 20px;
		display: flex;
		justify-content: flex-end;
		gap: 20px;
	}

	&--visible {
		visibility: visible;
		animation: visibility 300ms ease-in-out 200ms;
	}

	&--hidden {
		visibility: hidden;
		display: none;
		animation: visibility 300ms ease-in-out display 100ms ease-in-out 300ms;
	}
}
