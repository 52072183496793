@mixin containerFlex() {
  display: flex;
}

@mixin containerGrid($rowNumber, $columnNumber) {
  display: grid;
  grid-template-columns: repeat($columnNumber, 1fr);
  grid-template-rows: repeat($rowNumber, 1fr);
}

@mixin gridColumnsRowAuto($columnNumber) {
  display: grid;
  grid-template-columns: repeat($columnNumber, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  margin-bottom: 20px;
}

@mixin gridContainer() {
  display: grid;
  grid-template-rows: auto;
  gap: 20px;
  margin-bottom: 20px;
}
