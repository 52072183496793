@import "../../../Stylesheets/Base/color-palette";
.note-history-container {
	// padding: 0 1rem;
}

.note-history-accordion {
	margin-bottom: 0.5rem;
}

.note-history-item {
	margin-bottom: 0.3rem;
	border-bottom: 2px solid $grey80;
	display: flex;
	&__note-information {
		display: flex;
		gap: 0.3rem;
		&::after {
			content: " :";
		}
		&__author-information {
			font-weight: bold;
		}
	}
	&__delete {
		cursor: pointer;
	}
}
