.avatar-info {
  display: flex;
  align-items: center;
  gap: 10px;

  .avatar--img {
    width: 75px;
    height: auto;
    border-radius: 15px;
  }

  .information {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
    }
    .description {
      font-size: 14px;
    }
  }
}
