@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";
.message-container {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
	margin-bottom: 1em;
	padding: 0.5rem 1rem;

	&--inbound {
		background-color: $light-blue;
	}

	&--outbound {
		background-color: $light-grey;
	}

	&__image {
		&--popup {
			max-width: 100%;
			margin: 0 auto;
			border-radius: 20px;
		}
		&--container {
			text-align: center;
		}
	}
}

.message-information {
	width: 100%;
	display: grid;
	grid-template-columns: 25% 1fr 30px;

	@media only screen and (min-width: $extraLargeDeviceMinWidth) {
		width: 10%;
		display: block;
		// grid-template-areas: "left	mid	right";
	}

	&__author-name {
		font-weight: bold;
		&::after {
			content: " : ";
		}
	}
	&__type {
		&--inbound {
			color: $green;
		}
		&--outbound {
			color: $purple;
		}
	}
}

.message-content {
	width: 80%;
}
.message-attachment {
	justify-self: end;
	align-self: center;
	&__icon {
		width: 100%;
		cursor: pointer;
	}
}
