@import "../../../Stylesheets/Base/color-palette";
@import "../../../Stylesheets/Base/breakpoints";

.burger-navigation-button {
	border: none;
	background-color: transparent;
	// padding: 10px 20px;
	border-radius: 40px;
	color: $white !important;
	// &:focus {
	// 	color: $white !important;
	// }
	&--alternate {
		color: $blue !important;
	}
}
