@import "../../../Stylesheets/Base/color-palette";

.header {
  // padding-top: 7px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 2;
  background: $white;
  border-bottom: 2px solid $blue80;

  &__navigation {
    &__list {
      display: flex;

      gap: 20px;
      padding-right: 20px;

      &__item {
        list-style-type: none;
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &__link {
      padding: 10px 20px;
      border-radius: 40px;
      display: grid;
      grid-template-columns: 20px auto;
      gap: 10px;
      &:hover {
        // background-color: $primaryBackground;
        color: $blue80;
      }
      &--active {
        background-color: $blue80;
        color: $white !important;
        padding: 10px 20px;
        border-radius: 40px;
        display: grid;
        grid-template-columns: 20px auto;
        gap: 10px;
      }
    }
  }
}
