@import "../../../Stylesheets/Structure/structure";
@import "../../../Stylesheets/Base/breakpoints";
.grid-2-col {
	@include gridColumnsRowAuto(2);

	&--items-centered {
		align-items: center;
	}
}

.row {
	&--grid {
		display: grid;
		gap: 20px;
		margin-bottom: 1.3rem;
		&-1-col {
			@include gridColumnsRowAuto(1);
		}

		&-2-col {
			@include gridColumnsRowAuto(2);

			&--right-large {
				grid-template-columns: 1fr 2fr;
				@media only screen and (max-width: $extraLargeDeviceMinWidth) {
					grid-template-columns: 1fr;
					grid-template-rows: 1fr 1fr;
					grid-template-areas:
						"head"
						"body";
				}
			}

			&--left-large {
				grid-template-columns: 2fr 1fr;
				@media only screen and (max-width: $extraLargeDeviceMinWidth) {
					grid-template-columns: 1fr;
					grid-template-rows: 1fr 1fr;
					grid-template-areas:
						"head"
						"body";
				}
			}
		}

		&-4-col {
			@include gridColumnsRowAuto(4);
			@media only screen and (max-width: $extraLargeDeviceMinWidth) {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
			}
		}
	}
	&--flex {
		display: flex;
		gap: 20px;
		margin-bottom: 20px;

		&-1-col {
			@include containerFlex();
		}

		&--wrap {
			flex-wrap: wrap;
		}

		&--column {
			flex-direction: column;
		}
	}

	&--items-centered {
		justify-content: center;
		align-items: center;
	}
}

.container {
	@include containerFlex();
	justify-content: center;
	align-items: center;
	height: 100vh;
	@media screen and (min-width: $customMedium) {
		min-height: 100vh;
	}
}
.grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
}
