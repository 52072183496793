@import "../../../Stylesheets/Structure/structure";
@import "../../../Stylesheets/Base/color-palette"; 
.dashboard-container {
  @include containerFlex();
  justify-content: space-between;

  &__side-menu {
    @include containerFlex();
    // width: 20%;
    max-width: 18%;
    flex-direction: column;
  }
  &__main-content {
    @include containerFlex();
    width: 100%;
    flex-direction: column;
    padding: 20px 10px;
    background-color: $light-grey;
  }
}
