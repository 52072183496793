@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./color-palette";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
	font-family: "Poppins", sans-serif;
}

textarea {
	font-family: "Poppins", sans-serif;
}

.text {
	&-type {
		&--price {
			display: inline-block;

			&--ar {
				&::before {
					content: "Ar ";
				}
			}
			&--eur {
				&::before {
					content: "€ ";
				}
			}
			&--percentage {
				&::after {
					content: " %";
				}
			}
		}
	}

	&--bold {
		font-weight: 700;
	}

	&--color {
		&--green {
			color: $green;
		}
		&--cream {
			color: $cream;
		}
		&--blue {
			color: $blue;
		}
		&--red {
			color: $red;
		}
		&--grey-blue {
			color: $greyBlue;
		}
		&--dark-green {
			color: $darkGreen;
		}
		&--gold {
			color: $gold;
		}
	}
}
.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
