@import "../../../Stylesheets/Base/breakpoints";
@import "../../../Stylesheets/Base/_color-palette";

.salary-table {
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	&__header {
		display: grid;
		grid-template-columns: repeat(14, 1fr);
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid lightgray;
		text-transform: uppercase;
		font-size: 0.8rem;
		font-weight: 700;
		align-items: center;
		justify-content: center;
		color: $dark-gray;
		//background-color: rgb(0, 0, 0, 0.5);
		// background-color: rgb(33, 158, 188, 0.5);
	}
	&__row {
		display: grid;
		grid-template-columns: repeat(14, 1fr);
		border-bottom: 1px solid lightgray;
		font-size: 0.8rem;
		font-weight: 500;

		align-items: center;
		justify-content: center;
		background-color: transparent;
		height: fit-content;
	}

	&__item {
		word-break: break-word;
		height: 100%;
		text-align: center;

		span {
			padding-left: 2px;
			padding-right: 2px;
		}
	}

	&__cell {
		&--colored {
			&:nth-child(odd) {
				background-color: #fff8dd;
			}
		}
	}

	&__inner-column {
		display: flex;
		flex-direction: column;

		&__title {
			font-weight: 700;
		}

		&--grid-2 {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		&--grid-3 {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			span {
				padding: 5px;
			}

			@media screen and (max-width: $desktopMaxWidth) {
				display: none;
			}

			@media screen and (min-width: $veryLargeDeviceMinWidth) {
			}
		}

		&--row-1 {
			display: flex;

			@media screen and (max-width: $desktopMaxWidth) {
				visibility: visible;
			}

			@media screen and (min-width: $veryLargeDeviceMinWidth) {
				display: none;
			}
		}
	}
}
