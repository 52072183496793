.profile-overview-item-left,
.profile-info-item-left {
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		grid-template-rows: 1fr;
		margin-bottom: 5rem;
	}
	.card__medium {
		@media screen and (min-width: 768px) and (max-width: 1200px) {
			min-width: 100%;
		}
	}
}
.profile-overview-item-right,
.profile-info-item-right {
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		grid-template-rows: 1fr;
		gap: 50px;
	}
}
