@import "../../../Stylesheets/Base/color-palette";
.message-history-list {
  padding: 0.5em 1em;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &:-webkit-scrollbar-track {
    background: $grey80;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-600;
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray-800;
  }
}
