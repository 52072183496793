@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import "./color-palette";

@mixin outlinedIcons($fontSize) {
	font-family: "Material Icons Outlined";
	font-weight: normal;
	font-style: normal;
	font-size: $fontSize; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	cursor: pointer;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: "liga";
}

@mixin normalIcons($fontSize) {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: $fontSize; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: "liga";
}

.icon {
	&--outlined {
		@include outlinedIcons(24px);
	}

	&--filled {
		@include normalIcons(24px);
	}

	&--very-large {
		font-size: 6rem;
	}
	&--large {
		font-size: 33px;
	}
	&--medium {
	}
	&--small {
		font-size: 16px;
	}
}

.status-icon {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	&--inactive {
		background-color: red;
	}
	&--active {
		background-color: green;
	}
	&--pending {
		background-color: orange;
	}
}
