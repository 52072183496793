@import "../../../Stylesheets/Base/color-palette";
.error-notification {
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;
	&__error-block {
		margin: 0 auto;
		width: fit-content;
		padding: 1rem 2rem;
		background-color: $grey80;
		&__details {
			color: $red;
		}
	}
	&__title {
	}
}
