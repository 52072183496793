$large: 1400px;
$medium: 900px;
$customMedium: 810px;
$small: 600px;

$mobileMinWidth: 320px;
$mobileMaxWidth: 480px;
$tabletMinWidth: 481px;
$tabletMaxWidth: 768px;
$laptopMinWidth: 769px;
$laptopMaxWidth: 1024px;
$desktopMinWidth: 1025px;
$desktopMaxWidth: 1200px;
$veryLargeDeviceMinWidth: 1201px;
$veryLargeDeviceMaxWidth: 1650px;
$extraLargeDeviceMinWidth: 1651px;

.desktop {
	@media screen and (max-width: $customMedium) {
		display: none !important;
	}
}

.mobile {
	@media screen and (min-width: $customMedium) {
		display: none!important;
	}
}
