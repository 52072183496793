@import "../../Stylesheets/Base/color-palette";
@import "../../Stylesheets/Base/breakpoints"; 

.customer-card-view-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.customer-card-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2.5px 2.5px 5px 2.5px rgba(128, 128, 128, 0.247);
  @media  screen and (min-width: $customMedium) {
    flex-direction: row; 
    justify-content: space-between; 
    border-radius: 0;            
  }
  & .blue{
    color: $blue ; 
  }
  & .green{
    color: $green ; 
}
& .yellow{
    color: $yellow ; 
}
  &__account{
    font-size: 40px; 
    padding-top: 20px;
    &.desktop{
        font-size: 14px; 
        font-weight: 600; 
        color:white; 
        background-color: $blue;
        height: 20px; 
        width: 30px; 
        border-radius: 20px;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 0;

    }
  }
  &__title{
     font-size: 18px;
     font-weight: normal;  
     margin-bottom: 30px;
  }
  &__link{
   font-size: 12px; 
   color: $blue!important;
   &::after{
    content: "";
    display: inline-block;
    height: 2px;
    width: 100%; 
    background-color: $yellow;
    margin-bottom: 11px; 
   }
   &.desktop{
    margin-top: 10px; 
   }
  }
  &__description{
    color:$gray; 
  }
}
