@import "../../../Stylesheets/Base/breakpoints";

.stats-entry-form {
  margin-top: 10px;
  max-width: 100%;
  &__container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    gap: 20px;

    @media screen and (max-width: $desktopMaxWidth) {
      max-width: 100%;
      grid-template-columns: repeat(7, 120px);
    }
  }

  &__day {
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px 20px;
    text-decoration: underline;
  }
  &__element {
    display: grid;
    gap: 10px;
    // min-width: max-content;

    @media screen and (max-width: $desktopMaxWidth) {
      max-width: 100%;
    }
  }

  &__label {
    background: rgb(211, 211, 211, 0.2);
    border-radius: 4px;
  }

  &__input {
    min-height: 2rem;
    border: none;
    border-bottom: 1px solid rgb(0, 0, 0, 0.3);
    padding: 10px 20px;
  }
}
