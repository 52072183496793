.daily-overview-container {
  display: flex;
  gap: 5px;
  flex-direction: column;

  &__inner-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    &__card-title {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }
  }

  &__information {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
}
.assign-operator-dropdown-container {
  border: 1px solid lightgrey;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 1;
  right: 5%;
  top: 30%;
  visibility: hidden;
  width: 250px;
}
.assign-operator-dropdown-container.__dropped {
  visibility: visible;
}
.assign-operator-form .form {
  &__form-inputs {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  &__header {
    align-self: start;
    padding-left: 12px;
  }
  &__select {
    width: unset;
  }
}
.btn-group-container {
	display: flex;
	gap: 10px; 
}
