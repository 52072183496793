@import "../../Stylesheets/Base/color-palette";
@import "../../Stylesheets/Base/breakpoints";
.table {
	min-width: fit-content;
	width: 100%;
	margin-top: 20px;
	color: $grey-blue;
	font-size: 12px;
	&__header {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
		text-transform: uppercase;
		font-weight: 600;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		width: 100%;
		border-bottom: 1px solid $gray-300;
		& .table__item {
			margin-bottom: 10px;
		}
	}
	&__row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
		width: 100%;
		height: 50px;

		// @media screen and (min-width: $customMedium) {
		// 	height: fit-content;
		// }

		&:nth-child(even) {
			background-color: $light-blue;
		}
		&:last-child {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		&__actions {
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: #cfd8dc;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 150%;
			overflow: hidden;
			right: 20000px;
			transition: right 0.5s ease-in-out;

			& a:link,
			& a:visited {
				padding-right: 20px;
				color: $grey-blue;
			}
			& .icon--outlined {
				border-radius: 50%;
				width: 30px;
				height: 30px;
				background-color: rgb(255, 255, 255);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				cursor: pointer;

				&:hover {
					background-color: $gray-300;
				}
			}

			&--slide {
				right: 0;
				transition: right 0.3s ease-in-out;
			}
		}

		& .expand-right {
			display: none;
			&.show {
				display: flex;
			}
		}
		& .expand-left {
			display: flex;
			&.not-show {
				display: none;
			}
		}
		&--hidden {
			visibility: hidden;
		}
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: break-spaces;
		overflow: hidden;
		text-overflow: ellipsis;

		& .icon--outlined {
			color: $grey-blue;
		}
	}

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
	@keyframes slideout {
		from {
			margin-left: 0%;
			width: 100%;
		}

		to {
			margin-left: 100%;
			width: 300%;
		}
	}
}
