@import "../../Stylesheets/Base/_color-palette.scss";

.card--information {
  width: 50%;
}
.card--information p {
  margin-top: 20px;
}
.card--information__full-grid {
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.card--information__full-grid p {
  margin-top: 20px;
}
.card--information__full {
  flex-direction: column;
}
.card--information__full p {
  margin-top: 20px;
}

.card-header--left {
  justify-self: start;
}
.card-header--right {
  justify-self: end;
}
.card-header--left h6 {
  text-transform: uppercase;
  font-size: 16px; 
  font-weight: 500; 
  padding-top: 10px;
  padding-bottom: 10px;
  color: $blue;
  width: max-content; 
}
