/*Unassigned account List Style*/
@import "../../../Stylesheets/Base/color-palette";
.unassigned-account-list-container {
    border : 1px solid lightgrey;
    padding: 15px; 
    border-radius: 16px; 
    margin: 10px; 
    display: flex;
    justify-content: center; 
    flex-direction: column;
    align-items: center;
    background-color: rgb(233, 167, 167);
   
  
}
.unassigned-account-list a{
    color: red !important; 
}
.unassigned-account-list a:hover{
  color: white !important; 
}

.assignment-late{
    color: red; 
    font-size: 40px !important; 
    
}
.unassigned-account-title p{
  font-size: 12px; 
}
.unassigned-account-title h6{
    padding: 0; 
    text-align: center;
}
.assign-account-form .form{
  &__form-inputs{
    display: flex; 
    flex-direction: column;
    align-items: end;
   
  }
  &__header{
    align-self: start; 
    padding-left: 12px; 
  }
  &__select{
    width: unset; 
  }
}

