@import "../../../Stylesheets/Base/breakpoints";
.profile-information-container {
	padding: 0rem 0.35rem;
	@media only screen and (min-width: $mobileMinWidth) and (max-width: $medium) {
		padding: 5px;
	}

	.profile-summary {
		// display: flex;
		// justify-content: space-between;
		margin-bottom: 30px;
		&__picture {
			border-radius: 5px;
			max-width: 75%;
			box-shadow: 0 2px 5px #000;
			margin-bottom: 30px;
		}
	}
	.accordion {
		margin-bottom: 0.6rem;
	}
}
.profile-information {
	&__section-title {
		font-weight: bold;
		margin: 0.8em 0;
		text-decoration: underline;
	}
	&__information-block {
		padding: 0.3em;
		display: flex;
		gap: 0.3em;
	}

	&__label {
		font-weight: bold;
	}
}
