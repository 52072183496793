.image-gallery {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	cursor: pointer;
	&__selectable-image {
		max-width: 150px;
		&--selected {
			border: 1px solid black;
		}
	}
}
