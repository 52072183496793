@import "../../Stylesheets/Base/color-palette";
.tabs {
	display: flex;
	flex-direction: column;

	&__button-container {
		display: flex;
		justify-content: center;
		gap: 10px;

		&__button {
			padding: 20px 30px;
			color: $white;
			background-color: $blue;
			font-weight: 500;
			margin-bottom: 7px;

			&--active {
				background-color: $light-grey;
				color: #000;
				margin-bottom: -7px;
			}
		}
	}
	&__content-container {
		&__tab-content {
			display: none;
			.accordion__header {
				//background-color: $light-blue;
				background-color: $blue;
				color: $white;
			}
			&--active-tab {
				display: block;
			}
		}
	}
}
