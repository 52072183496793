@import "../../../Stylesheets/Base/breakpoints";
.login-form {
	//   max-width: 335px;

	&__form-actions-container {
		display: flex;
		width: 100%;
		justify-content: center;
		@media screen and (min-width: $customMedium) {
			margin-top: 0;
		}
	}

	&__button {
		display: inline-flex;
		align-items: center;
		width: 100%;
		justify-content: center;
	}
	&__additional-section {
		display: flex;
		gap: 30px;
	}
	.desktop {
		// input,
		// label,
		// button,
		// .form__error-message {
		// 	font-size: 0.7rem;
		// }
	}
	#is-client {
	}
}
