.add-profile-note-container {
  padding: 0 1rem;
}

.add-profile-note-form {
  display: flex;
  flex-direction: column;
  &__title {
    text-decoration: underline;
  }

  &__note-content {
    textarea {
      padding: 0.5rem;
      height: 7rem;
      resize: vertical;
      min-height: 5rem;
      max-height: 10rem;
      // font-family: "Open Sans", sans-serif;
      font-size: 1em;
    }
  }
}
