@import "../../Stylesheets/Base/color-palette";
.manage-table {
  &__data-row {
    position: relative;
    & .is-active {
      background-color: red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      &.ok {
        background-color: green;
      }
    }
  }
  &__card-header {
    & .add-icon {
      height: 30px;
      width: 30px;
      background-color: $blue80;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 50%;
      margin-top: 8px;
      & .material-icons-outlined {
        font-size: 18px;
      }
    }
  }
  & .element-actions.not-show {
    visibility: hidden;
  }
  &__actions-dropdown {
    &__container {
      position: relative;
    }
    &__content {
      display: none;
    

    &.active {
      display: block;
      position: absolute;
      background-color: bisque;
      right: 90%;
      top: -14%;
      height: 80px;
      padding: 5px;
    }
}
  }
}
