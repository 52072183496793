.message-list-card {
	width: 100%;
}

.message-list-table {
	&__header,
	&__row {
		display: grid;
		grid-template-columns: 150px 150px 1fr 1fr 80px;
	}
}
