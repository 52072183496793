@import "../../Stylesheets/Base/color-palette";

@mixin grid-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}

@mixin row-full {
	display: flex;
}
@mixin row-full--center {
	@include row-full();
	justify-content: center;
}

.form {
	display: flex;
	padding: 20px;
	flex-direction: column;
	gap: 10px;

	&__title {
		color: darkgray;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		padding: 20px 0;
		font-weight: 700;
		font-size: 26px;
		margin-bottom: 1.2rem;
	}
	&__inputs {
		margin-bottom: 40px;
	}

	&__input-group {
		padding: 10px;
		background-color: rgb(0, 0, 0, 0.1);
		margin-bottom: 20px;
		&__title {
			margin-bottom: 15px;
		}
		&:last-of-type {
			margin-bottom: 5px;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 10px;

		&__title {
			font-weight: 600;
			text-transform: capitalize;
		}

		&__description {
		}
	}

	&__label {
		width: 100%;
		font-weight: 500;
	}

	&__input {
		height: 2rem;
		// width: 100%;
		padding: 5px 10px;
		margin-left: 10px;
		border: 1px solid lightgray;
		border-radius: 2px;

		&:disabled {
			background-color: rgb(215, 215, 215, 0.5);
			cursor: not-allowed;
		}

		&--has-error {
			border: 1px solid $danger;
			background: $red5;
		}
	}

	&__select {
		height: 2rem;
		width: 100%;
		padding: 5px 10px;
		margin-left: 10px;
		border: 1px solid lightgray;
		border-radius: 2px;
		font-family: "Open Sans", sans-serif;
		font-size: 0.9rem;
		&:disabled {
			background-color: rgb(215, 215, 215, 0.5);
			cursor: not-allowed;
		}
	}

	&__row {
		&--full {
			@include row-full();
			&--content-centered {
				@include row-full--center();
				&--big-gap {
					@include row-full--center();
					gap: 40px;
				}
				&--medium-gap {
					@include row-full--center();
					gap: 20px;
				}
			}
		}

		&--grid {
			@include grid-row();
			&--centered {
				@include grid-row();
				justify-content: center;
			}
		}
	}

	&__input-container {
		display: flex;
		gap: 10px;
		flex-direction: column;
		margin-bottom: 10px;
		min-width: 100%;
	}

	&__input-group {
		display: flex;
		gap: 10px;

		&__title {
			text-transform: uppercase;
			padding-top: 10px;
			padding-bottom: 10px;
			color: grey;
		}

		&__subtitle {
			font-weight: 700;
			margin-bottom: 20px;
		}
	}

	&__form-actions {
		margin-top: 20px;
		display: flex;
		gap: 20px;
		align-self: flex-end;
	}

	&__floating-label {
		display: grid;
		position: relative;

		&__label {
			position: absolute;
			visibility: hidden;
			left: 0;
			width: 100%;
			transition: 0.2s;
			// font-size: 1rem;
			padding-left: 0.5rem;
			transform: translate3d(0, 0, 0) scale(1);
			transform-origin: left top;
			transition: transform ease 0.2s;
		}

		&__input {
			left: 0;
			height: 3rem;
			// padding-top: 1rem;
			padding-left: 0.5rem;
			border: hidden;
			border-bottom: 1px solid lightgray;
			margin-bottom: 10px;

			&--has-error {
				border: 1px solid $danger;
				background: $red5;
			}
		}

		&__input-icon {
			position: absolute;
			right: 0;
			color: rgb(0, 0, 0, 0.3);
			top: 20px;
			visibility: hidden;

			&--show {
				visibility: visible;
			}
		}

		&__textarea {
			left: 0;
			height: 3rem;
			padding-top: 1rem;
			padding-left: 0.5rem;
			border: hidden;
			border-bottom: 1px solid lightgray;
			margin-bottom: 10px;
			font-family: "Open Sans", sans-serif;
			resize: none;

			height: 15em;
		}

		&__textarea:focus + label {
			visibility: visible;

			padding-top: 5px;
			font-size: 0.7rem;
			color: #0062cc;
			font-weight: 600;
			transform: translateY(-2px) scale(0.9);
		}

		&__input:focus + label {
			visibility: visible;

			padding-top: 5px;
			font-size: 0.7rem;
			color: #0062cc;
			font-weight: 600;
			transform: translateY(-2px) scale(0.9);
		}

		&__input:valid + label {
			padding-top: 5px;
			font-size: 0.7rem;

			color: #66cc99;

			font-weight: 600;
			transform: translateY(-2px) scale(0.9);

			visibility: visible;
		}

		&__input:valid {
			border-bottom: 1px solid #66cc99;
		}

		&__textarea:valid {
			border-bottom: 1px solid #66cc99;
		}

		&__textarea:valid + label {
			padding-top: 5px;
			font-size: 0.7rem;

			color: #66cc99;
			font-weight: 600;

			visibility: visible;
		}
	}

	&__error-message {
		opacity: 0;
		color: $danger;
		// font-size: 0.8rem;
		font-weight: 600;
		margin-bottom: 0.8rem;

		&--has-error {
			opacity: 1;
			animation: opacity 500ms ease-in-out;
		}
	}
}
